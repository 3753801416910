import { supabase } from "@/ajax/clients/supabase";
/***
 * Sign in with Google email. This redirects the user to Google auth and they are redirected back when done.
 * @returns
 */
export const signInWithGoogle = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: "google",
  });

  return {
    data,
    error,
  };
};
