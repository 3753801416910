import { createClient } from "@supabase/supabase-js";

export const STORAGE_KEY = "sb.auth.token";

const createSupabaseClient = () => {
  return createClient(
    import.meta.env.VITE_PUBLIC_SUPABASE_URL!,
    import.meta.env.VITE_PUBLIC_SUPABASE_ANON_KEY!,
    {
      auth: {
        storageKey: STORAGE_KEY,
      },
    },
  );
};

export const supabase = createSupabaseClient();

export const getAccessToken = (): string | null => {
  const token = JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}");

  return token.access_token ?? null;
};
