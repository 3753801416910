import { CalendarRangeIcon, Clock } from "lucide-react";
import React from "react";
import { createFileRoute } from "@tanstack/react-router";
import ShiftPreferencesForm from "@/components/Forms/ShiftPreferencesForm";

export const Route = createFileRoute("/_authenticated/provider/shifts/")({
  component: ShiftPage,
});

function ShiftPage() {
  return (
    <div className="p-2 pt-4 md:pt-8 md:p-8 min-h-screen md:bg-gray-100">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Shifts</h2>
      <div className="bg-white p-6 rounded-lg border mb-4 animate-fade-in-delay-1">
        <p className="mb-4 text-gray-600">
          Job shifts will be available soon. While you wait, you can set up your
          shift preferences to ensure you're matched with the most suitable
          opportunities.
        </p>
        <span className="px-5 py-2 font-medium text-blue-600 bg-blue-50 rounded-full">
          Coming Soon
        </span>
      </div>
      <div className="animate-fade-in-delay-2 w-full p-8 rounded-sm  bg-white border dark:border-strokedark dark:bg-boxdark">
        <h2 className="text-lg font-bold text-gray-900 mb-1 animate-fade-in">
          Preferences
        </h2>
        <p className="text-gray-600 mb-6 animate-fade-in-delay-2">
          Fill in your shift preferences and we will let you know when shifts
          are available
        </p>
        <ShiftPreferencesForm />
      </div>
    </div>
  );
}
