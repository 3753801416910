import React, { useState } from "react";
import {
  BriefcaseBusiness,
  BriefcaseMedical,
  CheckIcon,
  ClipboardCheck,
  Upload,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { Button } from "../ui/button";

const steps = [
  {
    title: "Account Details",
    icon: ({ className }: { className: string }) => (
      <svg
        className={className}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 16"
      >
        <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"></path>
      </svg>
    ),
  },
  {
    title: "Job Preferences",
    icon: ({ className }: { className: string }) => (
      <BriefcaseBusiness className={className} />
    ),
  },
  {
    title: "Upload Documents",
    icon: ({ className }: { className: string }) => (
      <Upload className={className} />
    ),
  },
];

export default function OnboardingStepper({
  currentStepName,
}: {
  currentStepName: string;
}) {
  const stepIndex = steps.map((step) => step.title).indexOf(currentStepName);

  const [openTooltips, setOpenTooltips] = useState<string[]>([]);

  return (
    <div className="pl-5">
      <ol className="hidden lg:block relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
        {steps.map((step, index) => {
          let bgClassName =
            "absolute flex items-center justify-center w-10 h-10 rounded-full -start-5";
          let iconClassName = "w-4 h-4 text-white";
          if (stepIndex > index) {
            bgClassName += " bg-green-700";
          } else if (stepIndex === index) {
            bgClassName += " bg-blue-800";
          } else {
            bgClassName += " bg-slate-400";
          }

          return (
            <li key={step.title} className="[&:not(:last-child)]:mb-10 ms-8">
              <span className={bgClassName}>
                {stepIndex > index ? (
                  <CheckIcon className={iconClassName} />
                ) : (
                  step.icon({ className: iconClassName })
                )}
              </span>
              <h3 className="font-medium leading-9">{step.title}</h3>
            </li>
          );
        })}
      </ol>
      <ol className="flex items-center w-full lg:hidden">
        {steps.map((step, index) => {
          let bgClassName =
            "flex items-center justify-center w-10 h-10 rounded-full shrink-0";
          let iconClassName = "w-3.5 h-3.5 text-white lg:w-4 lg:h-4 text-white";
          if (stepIndex > index) {
            bgClassName += " bg-green-700";
          } else if (stepIndex === index) {
            bgClassName += " bg-blue-800";
          } else {
            bgClassName += " bg-slate-400";
          }

          const onOpenChange = (open: boolean) => {
            setOpenTooltips((prevState) => {
              if (open) {
                if (openTooltips.includes(step.title)) {
                  return prevState;
                } else {
                  return prevState.concat([step.title]);
                }
              } else {
                return prevState.filter((item) => item !== step.title);
              }
            });
          };

          return (
            <li
              key={step.title}
              className={`flex items-center w-full dark:text-blue-500 ${index === steps.length - 1 ? "" : "after:content-[''] after:w-full after:h-1 after:border-b after:border-slate-300 after:border-4 after:inline-block dark:after:border-blue-800"}`}
            >
              <TooltipProvider>
                <Tooltip
                  delayDuration={300}
                  open={openTooltips.includes(step.title)}
                  onOpenChange={onOpenChange}
                >
                  <TooltipTrigger asChild>
                    <Button
                      variant="none"
                      size="none"
                      onClick={() => {
                        onOpenChange(true);
                      }}
                      className={bgClassName}
                    >
                      {stepIndex > index ? (
                        <CheckIcon className={iconClassName} />
                      ) : (
                        step.icon({ className: iconClassName })
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{step.title}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </li>
          );
        })}
      </ol>
    </div>
  );
}
