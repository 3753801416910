import { supabase } from "@/ajax/clients/supabase";
export const getHealthcareRoles = async () => {
  // Get healthcare roles from supabase
  const { data, error } = await supabase
    .from("healthcare_professional_type")
    .select("id, name, category");

  if (error) throw error;

  return data;
};
