import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export const getProviderCommunicationPreferences = async () => {
  return callSupabaseFnFromClient<{
    renewals_email_notifications: boolean;
    renewals_phone_notifications: boolean;
    renewals_push_notifications: boolean;
  }>({
    fnName: "get_user_provider_communication_preferences",
  });
};
