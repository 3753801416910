import React from "react";
import RenewalTable from "@/components/renewal/RenewalTable";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { FileType } from "@/ajax/documents/getFiles";
import { useQuery } from "@tanstack/react-query";
import { renewalsQueryOptions } from "../../ajax/queries";

export const AdminRenewalsModal = ({
  file,
  user,
  onClose,
}: {
  onClose: () => void;
  user: {
    id: string;
  };
  file: FileType;
}) => {
  const renewalsQuery = useQuery(
    renewalsQueryOptions({
      fileId: file.id,
      switchUserId: user.id,
    }),
  );

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent className="w-full max-h-screen overflow-auto">
        <DialogHeader>
          <DialogTitle>Renewals</DialogTitle>
        </DialogHeader>

        {renewalsQuery.isLoading ? null : (
          <RenewalTable
            hideSource={true}
            userId={user.id}
            sourceUserCredentialsFileId={file.id}
            renewals={renewalsQuery.data ?? null}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
