import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";
export type User = {
  id: string;
  email: string;
  is_admin: boolean;
  first_name: string;
  last_name: string;
  phone_number: string;
  created_at: string;
};
export const getUser = async (): Promise<User | null> => {
  return callSupabaseFnFromClient<User>({
    fnName: "get_user",
  });
};
