import React, { useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from "lucide-react";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

interface PDFViewerProps {
  url: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ url }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(1.0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  const changePage = (offset: number): void => {
    setPageNumber((prevPageNumber) => {
      const newPageNumber = prevPageNumber + offset;
      return Math.min(Math.max(1, newPageNumber), numPages || 1);
    });
  };

  const previousPage = (): void => changePage(-1);
  const nextPage = (): void => changePage(1);

  const zoomIn = (): void =>
    setScale((prevScale) => Math.min(prevScale + 0.2, 3));
  const zoomOut = (): void =>
    setScale((prevScale) => Math.max(prevScale - 0.2, 0.5));

  return (
    <div className="flex flex-col items-center w-full max-w-4xl mx-auto p-4">
      {/* Controls */}
      <div className="flex items-center gap-4 mb-4">
        <button
          onClick={previousPage}
          disabled={pageNumber <= 1}
          className="flex items-center px-3 py-2 bg-gray-100 rounded-lg disabled:opacity-50"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>

        <span className="text-sm">
          Page {pageNumber} of {numPages || "--"}
        </span>

        <button
          onClick={nextPage}
          disabled={pageNumber >= (numPages || 0)}
          className="flex items-center px-3 py-2 bg-gray-100 rounded-lg disabled:opacity-50"
        >
          <ChevronRight className="w-5 h-5" />
        </button>

        <button
          onClick={zoomOut}
          className="flex items-center px-3 py-2 bg-gray-100 rounded-lg"
        >
          <ZoomOut className="w-5 h-5" />
        </button>

        <span className="text-sm">{Math.round(scale * 100)}%</span>

        <button
          onClick={zoomIn}
          className="flex items-center px-3 py-2 bg-gray-100 rounded-lg"
        >
          <ZoomIn className="w-5 h-5" />
        </button>
      </div>

      {/* PDF Document */}
      <div
        ref={containerRef}
        className="border rounded-lg p-4 bg-gray-50 w-full overflow-auto"
      >
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          className="flex justify-center"
        >
          <Page
            pageNumber={pageNumber}
            scale={scale}
            className="shadow-lg"
            renderAnnotationLayer={false}
            renderTextLayer={false}
            width={containerWidth ? containerWidth - 32 : undefined} // Subtract padding
          />
        </Document>
      </div>
    </div>
  );
};

export default PDFViewer;
