import { Link } from "@tanstack/react-router";
import React, { useEffect, useMemo, useState } from "react";
import {
  CheckCircle,
  Clock,
  FileText,
  FolderSearch,
  Settings,
  AlertCircle,
  Upload,
  CalendarRangeIcon,
} from "lucide-react";
import { useProviderUser } from "@/hooks/useProviderUser";
import { ProviderOnboardingStep } from "@/common/enums/ProviderOnboardingStep";
import { differenceInDays, parse } from "date-fns";
import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import { queryClient } from "../../../../ajax/queryClient";
import {
  filesQueryOptions,
  providerUserQuery,
} from "../../../../ajax/queries";
import { useSuspenseQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { DOCUMENT_CATEGORIES } from "@/consts/document_categories";
import { FileType } from "@/ajax/documents/getFiles";
import { ExpirationBadge } from "@/components/Badge/ExpirationBadge";

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const FileRow = ({ file }: { file: FileType }) => {
  return (
    <li
      key={file.id}
      className="flex justify-between items-center rounded-md"
    >
      <Link to="/provider/documents" className="w-full">
        <div className="flex w-full">
          <div className="font-medium w-2/3">{file.generated_title ?? file.file_name}</div>
          <div className="w-1/3">
            <ExpirationBadge expiresAt={file.expires_at} />
          </div>
        </div>
      </Link>
    </li>
  );
};

const renderDocumentStatus = (files: any[]) => {
  const expiringFiles = files.filter(file => {
    if (!file.expires_at) {
      return false;
    }
    const daysUntilExpire = differenceInDays(parse(file.expires_at, "yyyy-MM-dd", new Date()), new Date());
    return daysUntilExpire <= 60;
  });

  if (expiringFiles.length === 0) {
    return (
      <div className="mt-4">
        <div className="flex items-center">
          <CheckCircle className="h-5 w-5 mr-2" />
          <p className="font-semibold">All Documents Up to Date</p>
        </div>
        <p className="mt-2 text-sm">
          You have no documents that need renewal in the next 60 days.
        </p>
      </div>
    );
  }

  return (
    <ul className="mt-4 space-y-2">
      {expiringFiles.map((file, index) => (
        <FileRow file={file} key={index} />
      ))}
    </ul>
  );
};

export const Route = createFileRoute("/_authenticated/provider/home/")({
  loader: () =>
    Promise.all([
      queryClient.ensureQueryData(providerUserQuery()),
      queryClient.ensureQueryData(filesQueryOptions()),
    ]),
  component: HomePage,
});

function HomePage() {
  const providerUser = useProviderUser();
  const filesQuery = useSuspenseQuery(filesQueryOptions());
  const filesData = filesQuery.data;

  // Calculate the missing required documents
  const missingDocuments = useMemo(
    () =>
      DOCUMENT_CATEGORIES.filter((document) => {
        return !filesData.some((f) => document.fileTypeIds.includes(f.type_id));
      }),
    [filesData],
  );

  const [showDocumentsAlert, setShowDocumentsAlert] = useState(false);

  useEffect(() => {
    const alertDismissed = Cookies.get("documentsAlertDismissed");
    if (alertDismissed !== "true") {
      setShowDocumentsAlert(true);
    }
  }, []);

  const dismissAlert = () => {
    Cookies.set("documentsAlertDismissed", "true", { expires: 999 }); // Cookie expires in 7 days
    setShowDocumentsAlert(false);
  };

  if (!providerUser) {
    return;
  }

  const onboardingCompleted =
    providerUser.onboarding_step_id === ProviderOnboardingStep.COMPLETED;

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="p-6 max-w-4xl mx-auto">
        <div className="grid grid-cols-1 gap-6">
        
          <div className="bg-white p-6 rounded-lg border animate-fade-in-delay-2">
            <div className="flex items-center mb-4">
              <Clock className="h-5 w-5 mr-2 text-indigo-500" />
              <h2 className="text-xl font-semibold text-gray-800">Expiring Documents</h2>
            </div>
            {onboardingCompleted && renderDocumentStatus(filesData)}
          </div>

          {showDocumentsAlert && (
          <div className="animate-fade-in-delay-1 p-5 border rounded-lg border-l-4 bg-white relative">
            <div className="flex items-center">
              <AlertCircle className="h-6 w-6 mr-3 text-red-700" />
              <h2 className="text-xl font-semibold text-gray-800">
                Required Documents
              </h2>
            </div>
            <p className="mt-2 text-sm">
              Please upload the following required documents to complete your
              profile:
            </p>
            <ul className="mt-3 space-y-2">
              {missingDocuments.map((doc, index) => (
                <li key={index} className="flex items-center text-red-700">
                  <Upload className="h-4 w-4 mr-2" />
                  <span>{doc.name}</span>
                </li>
              ))}
            </ul>
            <div className="flex items-center mt-4">
              <Link to="/provider/documents">
                <Button variant="outline">Upload Documents</Button>
              </Link>
              <Button variant="ghost" onClick={dismissAlert} className="ml-2">
                Dismiss
              </Button>
            </div>
          </div>
        )}

          <div className="bg-white p-6 rounded-lg border animate-fade-in-delay-3">
            <div className="flex items-center mb-4">
              <CalendarRangeIcon className="h-5 w-5 mr-2 text-indigo-500" />
              <h2 className="text-xl font-semibold text-gray-800">Shifts</h2>
            </div>
            <span className="px-5 py-2 font-medium text-blue-600 bg-blue-50 rounded-full">
              Coming Soon
            </span>
            <p className="mt-4 text-gray-600">
              Medical shifts will be available soon. While you wait, you can set
              up your shift preferences to ensure you're matched with the most
              suitable opportunities.
            </p>
            <div className="w-50">
              <Link to="/provider/shifts">
                <Button
                  variant="outline"
                  size="none"
                  className="mt-4 flex items-center p-2"
                >
                  <Settings className="h-4 w-4 mr-2" />
                  Edit Shift Preferences
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
