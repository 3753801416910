import { createRootRoute, Outlet } from "@tanstack/react-router";
import { getUser, User } from "../ajax/auth/getUser";

export const Route = createRootRoute({
  beforeLoad: async (): Promise<{
    user: User | null;
  }> => {
    const user = await getUser();
    return {
      user,
    };
  },
});
