import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
export type RenewalId = {
  id: string;
};
export const deleteRenewal = async ({ id }: RenewalId) => {
  return callSupabaseFnFromClient<RenewalId>({
    fnName: "delete_user_renewal",
    args: {
      delete_id: id,
    },
  });
};
