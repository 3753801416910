"use client";
import React, { useEffect, useState } from "react";
import CredentialUploadStep from "./CredentialUploadStep";
import Welcome from "./WelcomeStep";
import { useProviderUser } from "@/hooks/useProviderUser";
import OnboardingStepper from "./OnboardingStepper";
import ConfirmationStep from "./ConfirmationStep";
import AccountDetailsOnboardingStep from "./AccountDetailsOnboardingStep";
import { useNavigate } from "@tanstack/react-router";
import JobPreferencesOnboardingStep from "./JobPreferencesOnboardingStep";
const ONBOARDING_STEPS = [
  { component: Welcome, title: "Welcome" },
  { component: AccountDetailsOnboardingStep, title: "Account Details" },
  { component: JobPreferencesOnboardingStep, title: "Job Preferences" },
  { component: CredentialUploadStep, title: "Upload Documents" },
];

const OnboardingContainer = () => {
  const [step, setStep] = useState<null | number>(null);
  const providerUser = useProviderUser();
  const navigate = useNavigate();

  const onComplete = () => {
    if (step == null) return;

    if (step === ONBOARDING_STEPS.length - 1) {
      // If we are at the last step, redirect to the provider dashboard
      navigate({
        to: "/provider/home",
      });
      return;
    }

    setStep(step + 1);
  };

  useEffect(() => {
    if (!providerUser) return;

    setStep(providerUser.onboarding_step_id || 0);
  }, [providerUser]);

  const Component = step == null ? step : ONBOARDING_STEPS[step]?.component;
  const currentStepName = step == null ? step : ONBOARDING_STEPS[step]?.title;

  if (!providerUser) {
    return null;
  }

  if (Component === Welcome) {
    return <Welcome onComplete={onComplete} />;
  }

  return (
    <div className="bg-gray-100 lg:p-2 lg:pt-4 md:pt-8 md:p-8 min-h-screen">
      <div className="grid grid-cols-12 lg:gap-4 lg:px-4">
        <div className="col-span-12 lg:col-span-4">
          {currentStepName != null && (
            <div className="bg-white p-8 lg:rounded-lg lg:border animate-fade-in-delay-1">
              <OnboardingStepper currentStepName={currentStepName} />
            </div>
          )}
        </div>
        <div className="col-span-12 lg:col-span-8">
          <div className="animate-fade-in-delay-2 w-full max-w-7xl p-4 md:p-8 lg:rounded-lg lg:border bg-white">
            {Component != null && (
              <Component providerUser={providerUser} onComplete={onComplete} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingContainer;
