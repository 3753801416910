import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
export type NewRenewal = {
  id: string;
  title: string;
  issued_at: string | undefined;
  expires_at: string;
  source_user_credentials_file_id: string | null;
};
export const createRenewal = async ({
  userId,
  title,
  issued_at,
  expires_at,
  source_user_credentials_file_id,
}: {
  userId?: string | undefined;
  title: string;
  issued_at?: string | undefined;
  expires_at: string;
  source_user_credentials_file_id: string | null;
}) => {
  return callSupabaseFnFromClient<NewRenewal>({
    fnName: "create_user_renewal",
    args: {
      switch_user_id: userId,
      title,
      issued_at,
      expires_at,
      source_user_credentials_file_id:
        source_user_credentials_file_id ?? undefined,
    },
  });
};
