import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";
import { Json } from "../../common/types/database.types";
export type FileType = {
  id: string;
  batch_id: string;
  file_name: string;
  generated_title: string | null;
  type_id: number;
  type_name: string;
  type_category: string;
  data: Json;
  created_at: string;
  renewals: {
    id: string;
    title: string;
    expires_at: string;
    issued_at: string;
  }[];
  expires_at: string;
};

export const getFiles = async ({
  id,
  switchUserId,
}: {
  id?: string | undefined;
  switchUserId?: string | undefined;
}): Promise<FileType[]> => {
  return await callSupabaseFnFromClient<FileType[]>({
    fnName: "get_user_credentials_files",
    args: {
      filter_id: id,
      switch_user_id: switchUserId,
    },
  });
};
