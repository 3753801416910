import { supabase } from "@/ajax/clients/supabase";
export const getFileSignedUrl = async ({
  userId,
  fileId,
  download = false,
}: {
  userId: string;
  fileId: string;
  download?: boolean;
}): Promise<string> => {
  const { data, error } = await supabase.storage
    .from("credentials")
    .createSignedUrl(`${userId}/${fileId}`, 60 * 60 * 24, {
      download,
    });

  if (error) {
    throw error;
  }

  return data.signedUrl;
};
