import { supabase } from "@/ajax/clients/supabase";
import { AuthError, AuthOtpResponse, User } from "@supabase/supabase-js";

export const signInWithEmailLink = async ({
  email,
  shouldCreateUser = false,
}: {
  email: string;
  shouldCreateUser?: boolean;
}): Promise<AuthError | null> => {
  const { error } = await supabase.auth.signInWithOtp({
    email,
    options: {
      shouldCreateUser,
    },
  });

  return error;
};
