import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { communicationPreferencesQueryOptions } from "../../ajax/queries";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "../ui/form";
import { updateProviderCommunicationPreferences } from "../../ajax/settings/updateProviderCommunicationPreferences";
import Loading from "../Loading";

type FormType = {
  renewals_email_notifications: boolean;
  renewals_phone_notifications: boolean;
  renewals_push_notifications: boolean;
};

export default function NotificationSettingsForm({}) {
  const queryClient = useQueryClient();
  const communicationPreferencesQuery = useSuspenseQuery(
    communicationPreferencesQueryOptions(),
  );

  // 'idle', 'loading', 'success', 'error'
  const [buttonState, setButtonState] = useState("idle");
  const form = useForm<FormType>({
    defaultValues: communicationPreferencesQuery.data,
  });

  const mutation = useMutation({
    mutationFn: updateProviderCommunicationPreferences,
    onSuccess: () => {
      queryClient.invalidateQueries(communicationPreferencesQueryOptions());
      setButtonState("success");
    },
    onError: () => {
      setButtonState("error");
    },
  });

  const onSubmit = (data: FormType) => {
    setButtonState("loading");
    mutation.mutate({
      renewalsEmailNotifications: data.renewals_email_notifications,
      renewalsPushNotifications: data.renewals_push_notifications,
      renewalsPhoneNotifications: data.renewals_phone_notifications,
    });
  };

  useEffect(() => {
    let timer: number | undefined;
    if (buttonState === "success" || buttonState === "error") {
      // @ts-ignore
      timer = setTimeout(() => {
        setButtonState("idle");
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [buttonState]);

  const getButtonText = () => {
    switch (buttonState) {
      case "loading":
        return <Loading className="w-6 h-6" />;
      case "success":
        return "Settings Saved!";
      case "error":
        return "Error Saving Settings";
      default:
        return "Save Changes";
    }
  };

  const getButtonColor = () => {
    switch (buttonState) {
      case "success":
        return "bg-green-500 hover:bg-green-600";
      case "error":
        return "bg-red-500 hover:bg-red-600";
      default:
        return "";
    }
  };

  return (
    <Form {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
        <Card className="w-full">
          <CardHeader>
            <CardTitle>Notifications</CardTitle>
            <CardDescription>
              Manage your notification preferences for renewals
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-4 rounded-lg border p-4">
              <h3 className="text-lg font-bold">Crendentials Expiration</h3>
              <FormField
                control={form.control}
                name="renewals_email_notifications"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between pl-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        Email Notifications
                      </FormLabel>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="renewals_phone_notifications"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between pl-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        Text Message Notifications
                      </FormLabel>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="renewals_push_notifications"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between pl-4">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        Mobile Push Notifications
                      </FormLabel>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </CardContent>
          <CardFooter>
            <Button
              size="none"
              className={`w-full py-4 ${getButtonColor()}`}
              type="submit"
              disabled={
                form.formState.isSubmitting || buttonState === "loading"
              }
            >
              {getButtonText()}
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
