import CredentialsStepper from "@/components/Steppers/CredentialsStepper";
import React from "react";
import "react-international-phone/style.css";
import PersonalInfoForm from "@/components/PersonalInfoForm";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/provider/credentials/personal-information/",
)({
  component: PersonalInformationPage,
});

function PersonalInformationPage() {
  const navigate = useNavigate();

  const onSubmit = () => {
    // navigate({ to: '/credentialing/application/job-preferences' })
  };

  return (
    <>
      <h2 className="text-title-md2 font-bold text-black dark:text-white">
        Credentials
      </h2>
      <div>
        <div className="grid grid-cols-12 lg:gap-4">
          <div className="col-span-12 lg:col-span-4 xl:pl-8 py-10 lg:pb-0 lg:pt-20 animate-fade-in-delay-1">
            <CredentialsStepper currentStepName="Personal Info" />
          </div>
          <div className="col-span-12 lg:col-span-8">
            <div className="lg:m-10 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark animate-fade-in-delay-2">
              <div className="border-b border-stroke px-6.5 py-4 dark:border-strokedark">
                <h2 className="text-xl font-medium text-black dark:text-white">
                  Personal Information
                </h2>
              </div>
              <PersonalInfoForm onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
