import React from "react";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { FileType } from "@/ajax/documents/getFiles";
import FileVersionPreview from "./FileVersionPreview";

export const FilePreviewModal = ({
  file,
  user,
  onClose,
}: {
  onClose: () => void;
  user: {
    id: string;
  };
  file: FileType;
}) => {
  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogHeader></DialogHeader>
      <DialogContent className="w-full h-screen overflow-auto">
        <FileVersionPreview user={user} file={file} onDelete={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default FilePreviewModal;
