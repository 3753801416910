import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useState } from "react";
import { useProviderUser } from "@/hooks/useProviderUser";
import { Share2, UserPlus, DollarSign, InfoIcon } from "lucide-react";
import { format } from "date-fns";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  createReferralLinkQueryOptions,
  referralsQueryOptions,
} from "@/ajax/queries";
import { queryClient } from "@/ajax/queryClient";
import { ReferralType } from "@/ajax/referrals/getReferrals";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

function ReferralsPage() {
  const [copied, setCopied] = useState(false);

  const { data: referrals } = useSuspenseQuery(referralsQueryOptions());
  const { data: referralLink } = useSuspenseQuery(
    createReferralLinkQueryOptions(),
  );

  const referralUrl = `${window.location.origin}/auth/signin?l=${referralLink}`;

  const copyToClipboard = () => {
    if (referralUrl) {
      navigator.clipboard.writeText(referralUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const hasSuccessfulReferral = referrals.some(
    (ref: ReferralType) => ref.status_name === "Complete",
  );

  return (
    <div className="p-8">
      <div className="text-center mb-8">
        <h1 className="text-2xl font-bold mb-2">
          Invite Your Friends to Saile
        </h1>
        <p className="text-xl text-gray-600">
          Earn <span className="font-semibold text-green-600">$20</span> for
          each friend who signs up and completes their profile!
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-sm text-center">
          <Share2 className="w-8 h-8 mx-auto mb-4 text-blue-500" />
          <h3 className="font-semibold mb-2">Share Your Link</h3>
          <p className="text-gray-600 text-sm">
            Share your unique referral link with your friends and colleagues
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-sm text-center">
          <UserPlus className="w-8 h-8 mx-auto mb-4 text-green-500" />
          <h3 className="font-semibold mb-2">Friends Sign Up</h3>
          <p className="text-gray-600 text-sm">
            When they sign up using your link, you'll both be eligible for
            rewards
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-sm text-center">
          <DollarSign className="w-8 h-8 mx-auto mb-4 text-yellow-500" />
          <h3 className="font-semibold mb-2">Earn Rewards</h3>
          <p className="text-gray-600 text-sm">
            Get $20 for each friend who successfully signs up!
          </p>
        </div>
      </div>

      <div className="bg-white py-6 rounded-lg mb-8">
        <h2 className="text-lg font-semibold mb-4">Your Referral Link</h2>
        <div className="flex gap-4 items-center">
          <input
            readOnly
            value={referralUrl}
            className="flex-1 p-2 border rounded bg-gray-50"
            onClick={(e) => e.currentTarget.select()}
          />
          <Button onClick={copyToClipboard}>
            {copied ? "Copied!" : "Copy Link"}
          </Button>
        </div>
      </div>

      <div className="bg-white">
        <div className="py-6 flex justify-between items-center">
          <h2 className="text-lg font-semibold">Your Referrals</h2>
          {hasSuccessfulReferral && (
            <Button variant="outline" className="flex items-center gap-2">
              <DollarSign className="w-4 h-4" />
              Claim Your Rewards
            </Button>
          )}
        </div>
        {referrals.length === 0 ? (
          <div className="p-8 text-center">
            <p className="text-gray-500 mb-4">
              You haven't referred anyone yet.
            </p>
            <p className="text-gray-500">
              Share your referral link above to start earning rewards!
            </p>
          </div>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Referral ID</TableHead>
                <TableHead>Date Referred</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {referrals.map((referral: ReferralType) => (
                <TableRow key={referral.id}>
                  <TableCell>{referral.id}</TableCell>
                  <TableCell>
                    {format(new Date(referral.referred_at), "MMMM d, yyyy")}
                  </TableCell>
                  <TableCell className="flex items-center gap-2">
                    {referral.status_name}
                    {referral.status_name === "In Progress" && (
                      <Popover>
                        <PopoverTrigger>
                          <InfoIcon className="h-4 w-4 text-gray-500" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <p className="text-sm">
                            This person has started their sign up process, but
                            has not completed it yet.
                          </p>
                        </PopoverContent>
                      </Popover>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/provider/referrals/")({
  component: ReferralsPage,
  loader: () =>
    Promise.all([
      queryClient.ensureQueryData(referralsQueryOptions()),
      queryClient.ensureQueryData(createReferralLinkQueryOptions()),
    ]),
});
