import { ProviderOnboardingStep } from "@/common/enums/ProviderOnboardingStep";
import { supabase } from "@/ajax/clients/supabase";

export async function updateProviderOnboardingStep(
  stepId: ProviderOnboardingStep,
): Promise<{ error: any }> {
  // This method doesn't return anything, so we don't need data
  const { error } = await supabase.rpc("update_provider_onboarding_step", {
    step_id: stepId,
  });

  return {
    error,
  };
}
