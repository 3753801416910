import { supabase } from "@/ajax/clients/supabase";
export const createFiles = async ({ fileNames }: { fileNames: string[] }) => {
  const { data, error } = await supabase
    .rpc("create_user_credentials_file_versions", {
      file_names: fileNames,
    })
    .returns<
      {
        id: string;
        file_name: string;
      }[]
    >();
  return { data, error };
};
