import { getProviderUser } from "@/ajax/auth/getProviderUser";
import { ProviderUser } from "@/common/types/ProviderUser";
import { useSuspenseQuery } from "@tanstack/react-query";
import { providerUserQuery } from "../ajax/queries";

function useProviderUser(): ProviderUser {
  const query = useSuspenseQuery(providerUserQuery());
  return query.data;
}

export { useProviderUser };
