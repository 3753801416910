import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { AlertCircle, Mail } from "lucide-react";
import Loading from "@/components/Loading";
import { Input } from "@/components/ui/input";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { signInOTPToken } from "@/ajax/auth/signInOTPToken";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { createReferral } from "@/ajax/referrals/createReferral";

const LOGO_PATH = "/static/images/logo.png";
const AUTH_CODE_LENGTH = Number(
  import.meta.env.VITE_PUBLIC_AUTH_CODE_LENGTH ?? 8,
);

const EmailVerification = ({
  email,
  referralCode,
  onComplete,
  onBack,
  onResend,
}: {
  email: string;
  referralCode?: string;
  onComplete: () => void;
  onBack: () => void;
  onResend: () => Promise<void>;
}) => {
  const [code, setCode] = useState(Array(AUTH_CODE_LENGTH).fill(""));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isResending, setIsResending] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, AUTH_CODE_LENGTH);
  }, []);

  const handleCodeChange = (index: number, value: string) => {
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value !== "") {
        if (index < AUTH_CODE_LENGTH - 1) {
          inputRefs.current[index + 1]?.focus();
        } else {
          handleSubmit(newCode);
        }
      }
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === "Backspace" && index > 0 && code[index] === "") {
      e.preventDefault();
      const newCode = [...code];
      newCode[index - 1] = "";
      setCode(newCode);
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = async (submittedCode: string[] = code) => {
    if (submittedCode.join("").length === AUTH_CODE_LENGTH) {
      setIsSubmitting(true);
      const code = submittedCode.join("");
      const { error, user } = await signInOTPToken({
        email,
        token: code,
      });

      if (user && referralCode) {
        await createReferral({
          referralCode: referralCode,
        });
      }

      if (!error) {
        onComplete();
      } else {
        setError("Invalid Code");
        setIsSubmitting(false);
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const pastedCode = pastedData.slice(0, AUTH_CODE_LENGTH).split("");
    const newCode = [...code];
    pastedCode.forEach((digit, index) => {
      if (/^[0-9]$/.test(digit)) {
        newCode[index] = digit;
      }
    });
    setCode(newCode);
    if (newCode.join("").length === AUTH_CODE_LENGTH) {
      handleSubmit(newCode);
    } else {
      inputRefs.current[newCode.filter(Boolean).length]?.focus();
    }
  };

  const handleResend = async () => {
    setIsResending(true);
    await onResend();
    setIsResending(false);
    // Clear errors and code after resend
    setError(null);
    setCode(Array(AUTH_CODE_LENGTH).fill(""));

    // Close dialog
    setDialogOpen(false);

    setTimeout(() => {
      // Focus first input after clearing
      inputRefs.current[0]?.focus();
    }, 0);
  };

  return (
    <main className="min-h-screen flex flex-col items-center justify-center md:bg-gray-100 p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white p-8 rounded-lg max-w-md w-full md:border"
      >
        <div className="text-center mb-8">
          <img
            src={LOGO_PATH}
            alt="Saile Company Logo"
            width={150}
            height={60}
            className="mx-auto mb-6"
          />
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Mail size={64} className="mx-auto text-primary mb-4" />
          </motion.div>
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            Check Your Email Inbox
          </h1>
          <p className="text-gray-600 mb-2">We've sent a code to:</p>
          <p className="text-primary font-semibold text-lg">{email}</p>
        </div>
        <div className="font-bold mb-2">EMAIL CODE:</div>
        <div className="space-y-6">
          {error && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                Invalid code. Please check your email.
              </AlertDescription>
            </Alert>
          )}
          <div className="flex justify-center space-x-1">
            {code.map((digit, index) => (
              <Input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                inputMode="numeric"
                autoFocus={index == 0}
                maxLength={1}
                value={digit}
                onChange={(e) => handleCodeChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={handlePaste}
                variant="none"
                className="w-11 h-18 text-center text-3xl font-semibold placeholder:text-opacity-10"
                disabled={isSubmitting}
              />
            ))}
          </div>
          <div className="flex justify-center items-center">
            {isSubmitting && <Loading className="w-10 h-10" />}
          </div>
        </div>

        <div className="text-center mt-4">
          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="link" className="text-indigo-600">
                Didn't receive the code?
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Didn't receive the code?</DialogTitle>
                <DialogDescription>
                  Choose what you'd like to do next
                </DialogDescription>
              </DialogHeader>
              <div className="space-y-4 py-4">
                <Button
                  onClick={handleResend}
                  disabled={isResending}
                  className="w-full"
                >
                  {isResending ? <Loading className="w-4 h-4 mr-2" /> : null}
                  Resend code to {email}
                </Button>
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t" />
                  </div>
                  <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-background px-2 text-muted-foreground">
                      Or
                    </span>
                  </div>
                </div>
                <Button onClick={onBack} variant="outline" className="w-full">
                  Try a different email
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </motion.div>
    </main>
  );
};

export default EmailVerification;
