import React from "react";
import AccountDetailsOnboardingForm from "./AccountDetailsOnboardingForm";
import ShiftPreferencesForm from "../Forms/ShiftPreferencesForm";
import { updateProviderOnboardingStep } from "../../ajax/onboarding/updateProviderOnboardingStep";
import { ProviderOnboardingStep } from "../../common/enums/ProviderOnboardingStep";

export default function JobPreferencesOnboardingStep({
  onComplete,
}: {
  onComplete: () => void;
}) {
  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-1 animate-fade-in">
        Job Preferences
      </h2>
      <p className="text-gray-600 mb-6 animate-fade-in-delay-2">
        Fill in your job preferences to get started
      </p>
      <ShiftPreferencesForm
        onComplete={async () => {
          await updateProviderOnboardingStep(
            ProviderOnboardingStep.JOB_PREFERENCES,
          );
          onComplete();
        }}
      />
    </div>
  );
}
