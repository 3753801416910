import React, { useMemo, useState, useRef, useEffect } from "react";
import Loading from "@/components/Loading";
import { useProviderUser } from "@/hooks/useProviderUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addMonths, formatDate } from "date-fns";
import {
  ArrowUpDown,
  EllipsisVertical,
  FileText,
  FolderSearch,
  Pencil,
  Plus,
  Search,
  Trash,
} from "lucide-react";
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { RenewalForm } from "./RenewalForm";
import { queryClient } from "../../ajax/queryClient";
import { Renewal } from "@/ajax/renewals/getRenewals";
import { deleteRenewal } from "../../ajax/renewals/deleteRenewal";
import { DeleteDialog } from "../DeleteDialog/DeleteDialog";
import { isProviderOnboardingComplete } from "../../common/utils/isProviderOnboardingComplete";
import FilePreviewModal from "../FilePreview/FilePreviewModal";
import { filesQueryOptions } from "@/ajax/queries";
import { FileType } from "@/ajax/documents/getFiles";

const RenewalTable = ({
  renewals,
  sourceUserCredentialsFileId = null,
  hideSource = false,
  buttonSecondary = false,
  userId,
}: {
  renewals: Renewal[] | null;
  sourceUserCredentialsFileId?: string | null;
  buttonSecondary?: boolean;
  hideSource?: boolean;
  userId?: string | null;
}) => {
  const providerUser = useProviderUser();
  const [selectedFile, setSelectedFile] = useState<FileType | null>(null);
  const [selectedEditRenewal, setSelectedEditRenewal] =
    useState<Renewal | null>(null);
  const [selectedDeleteRenewal, setSelectedDeleteRenewal] =
    useState<Renewal | null>(null);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const queryClientRef = useQueryClient();

  const { mutate: mutateDeleteRenewal } = useMutation({
    mutationFn: deleteRenewal,
    networkMode: "always",
    onSuccess() {
      queryClientRef.invalidateQueries({ queryKey: ["renewals"] });
      setSelectedDeleteRenewal(null);
    },
  });

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const handleEditComplete = (savedRowId: string) => {
    queryClientRef.invalidateQueries({ queryKey: ["renewals"] }).then(() => {
      setSelectedEditRenewal(null);
      setHighlightedRowId(savedRowId);
    });
  };

  useEffect(() => {
    if (highlightedRowId) {
      const highlightRow = () => {
        const row = tableRef.current?.querySelector(
          `[data-row-id="${highlightedRowId}"]`,
        );
        if (row) {
          row.scrollIntoView({ behavior: "smooth", block: "center" });
          row.classList.add("bg-yellow-100");
          setTimeout(() => {
            row.classList.remove("bg-yellow-100");
            setHighlightedRowId(null);
          }, 3000);
        }
      };

      // Try to highlight the row immediately
      highlightRow();

      // If the row is not found, set up a retry mechanism with a maximum number of attempts
      let attempts = 0;
      const maxAttempts = 10; // Adjust this value as needed
      const retryInterval = setInterval(() => {
        attempts++;
        if (attempts >= maxAttempts) {
          clearInterval(retryInterval);
          console.warn(
            `Failed to highlight row with id ${highlightedRowId} after ${maxAttempts} attempts`,
          );
          setHighlightedRowId(null);
        } else {
          highlightRow();
        }
      }, 200); // Retry every 200ms

      return () => clearInterval(retryInterval);
    }
  }, [highlightedRowId, renewals]);

  const handleStartDelete = (renewal: Renewal) => {
    setSelectedDeleteRenewal(renewal);
  };

  const handleViewSource = async (fileId: string | null) => {
    if (!fileId) {
      return;
    }
    const result = await queryClient.fetchQuery(filesQueryOptions());
    const file = result.find((file: FileType) => file.id === fileId);
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDelete = () => {
    if (!selectedDeleteRenewal) {
      return;
    }
    mutateDeleteRenewal({
      id: selectedDeleteRenewal.id,
    });
  };

  type RenewalColumnDef = ColumnDef<Renewal> & {
    meta?: {
      className?: string;
    };
  };

  const columns: RenewalColumnDef[] = useMemo(
    () => [
      {
        id: "title",
        accessorKey: "title",
        header: "Title",
        meta: {
          className: "w-7/12 lg:w-6/12",
        },
        cell: ({ row }) => {
          return (
            <div className="flex items-center">
              <div>
                <div>{row.getValue("title")}</div>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "issued_at",
        meta: {
          className: "hidden lg:table-cell lg:w-2/12",
        },
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Issued
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          return (
            <div className="text-sm">
              <div>
                {row.getValue("issued_at")
                  ? formatDate(row.getValue("issued_at"), "MMM dd, yyyy")
                  : "-"}
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "expires_at",
        meta: {
          className: "w-3/12 lg:w-3/12",
        },
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Expires
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const expiresAt = new Date(row.getValue("expires_at"));
          let variant: "destructive" | "warning" | "outline" = "outline";
          if (expiresAt < addMonths(new Date(), 1)) {
            variant = "destructive";
          } else if (expiresAt < addMonths(new Date(), 2)) {
            variant = "warning";
          }

          return (
            <div className="text-sm">
              <Badge variant={variant}>
                {formatDate(expiresAt, "MMM dd, yyyy")}
              </Badge>
            </div>
          );
        },
      },
      {
        id: "actions",
        enableHiding: false,
        meta: {
          className: "w-1/12",
        },
        cell: ({ row }) => {
          const [open, setOpen] = useState(false);
          return (
            <DropdownMenu open={open} onOpenChange={setOpen}>
              <DropdownMenuTrigger asChild>
                <Button
                  type="button"
                  variant="outline"
                  size="none"
                  className="h-8 w-8 p-2"
                >
                  <span className="sr-only">Open menu</span>

                  <EllipsisVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {!hideSource && row.original.file_id && (
                  <DropdownMenuItem
                    onClick={() => {
                      setOpen(false);
                      handleViewSource(row.original.file_id);
                    }}
                  >
                    <Search className="w-3 h-3 mr-2" /> View source
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  onClick={() => {
                    setOpen(false);
                    setSelectedEditRenewal(row.original);
                  }}
                >
                  <Pencil className="w-3 h-3 mr-2" /> Edit
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    setOpen(false);
                    handleStartDelete(row.original);
                  }}
                >
                  <Trash className="text-red-700 w-3 h-3 mr-2" />
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: renewals || [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (!renewals || !providerUser) {
    return (
      <div className="flex justify-center items-center w-full">
        <Loading className="w-20 h-20" />
      </div>
    );
  }

  return (
    <div>
      <div className="flex w-full justify-between mb-4">
        <h2 className="text-title-md2 font-bold text-black dark:text-white">
          Renewals
        </h2>
        <Button
          onClick={() =>
            setSelectedEditRenewal({
              source_user_credentials_file_id: sourceUserCredentialsFileId,
            } as Renewal)
          }
          variant={buttonSecondary ? "outline" : "default"}
        >
          <Plus className="w-5 h-5 mr-1" />
          Add Renewal
        </Button>
      </div>
      {renewals.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-32 sm:h-64 text-center">
          <p className="text-lg sm:text-xl font-medium text-gray-900 mb-1 sm:mb-2">
            No renewals available
          </p>
          <p className="text-sm">Add renewal above or upload a new document.</p>
        </div>
      ) : (
        <div className="animate-fade-in-delay-1 bg-white rounded-md w-full border">
          <Table ref={tableRef}>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        className={
                          (header.column.columnDef as RenewalColumnDef).meta
                            ?.className
                        }
                        key={header.id}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    data-row-id={row.original.id}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        className={
                          (cell.column.columnDef as RenewalColumnDef).meta
                            ?.className
                        }
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    <div className="flex flex-col items-center justify-center h-32 sm:h-64 text-center">
                      <FileText className="w-12 h-12 sm:w-16 sm:h-16 mb-3 sm:mb-4" />
                      <p className="text-lg sm:text-xl font-medium text-gray-900 mb-1 sm:mb-2">
                        No documents yet
                      </p>
                      <p className="text-sm">
                        Get started by uploading your first document above.
                      </p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
      <Dialog
        open={!!selectedEditRenewal}
        onOpenChange={(open) => {
          if (!open) {
            setSelectedEditRenewal(null);
          }
        }}
      >
        {selectedEditRenewal && (
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {selectedEditRenewal.id ? "Edit" : "Add"} Renewal
              </DialogTitle>
              <DialogDescription>
                {selectedEditRenewal.id
                  ? "Editing this only affects for your notifications. It does not affect credentialing. Make sure to upload your new document as well."
                  : "Add a new renewal to track your document expiration."}
              </DialogDescription>
            </DialogHeader>
            <RenewalForm
              userId={userId}
              renewal={selectedEditRenewal}
              onComplete={handleEditComplete}
            />
          </DialogContent>
        )}
      </Dialog>
      {selectedDeleteRenewal && (
        <DeleteDialog
          open={true}
          onOpenChange={(open) => {
            if (!open) {
              setSelectedDeleteRenewal(null);
            }
          }}
          onConfirm={handleDelete}
        />
      )}
      {selectedFile && (
        <FilePreviewModal
          user={providerUser}
          file={selectedFile}
          onClose={() => setSelectedFile(null)}
        />
      )}
    </div>
  );
};

export default RenewalTable;
