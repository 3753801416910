import { ZipWriter, BlobWriter, Uint8ArrayReader } from "@zip.js/zip.js";
import { supabase } from "../clients/supabase";
import { FileType } from "./getFiles";

export async function downloadAllFilesZip({
  userId,
  files,
  batchSize = 10,
}: {
  userId: string;
  files: FileType[];
  batchSize?: number;
}) {
  const zipFileWriter = new BlobWriter("application/zip");
  const zipWriter = new ZipWriter(zipFileWriter);

  try {
    // Process files in batches
    for (let i = 0; i < files.length; i += batchSize) {
      const batch = files.slice(i, i + batchSize);

      // Download files in parallel
      const downloadPromises = batch.map(async (file: FileType) => {
        const { data, error } = await supabase.storage
          .from("credentials")
          .download(`${userId}/${file.id}`);

        if (error) {
          throw error;
        }

        return { name: file.file_name, data };
      });

      // Wait for all downloads in the current batch to complete
      const downloadedFiles = await Promise.all(downloadPromises);

      // Add downloaded files to the zip
      for (const file of downloadedFiles) {
        await zipWriter.add(
          file.name,
          new Uint8ArrayReader(new Uint8Array(await file.data.arrayBuffer())),
        );
      }
    }

    // Close the zip writer and get the zip file as a blob
    const zipBlob = await zipWriter.close();

    // Create a download link and trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = "download.zip";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error("Error creating zip file:", error);
  }
}
