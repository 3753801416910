import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
export type Renewal = {
  id: string;
  title: string;
  description: string | null;
  expires_at: string;
  issued_at: string;
  metadata: any | null;
  source_user_credentials_file_id: string | null;
  file_id: string;
};
export const getRenewals = async ({
  switchUserId,
  fileId,
}: {
  switchUserId?: string;
  fileId?: string;
}) => {
  return callSupabaseFnFromClient<Renewal[]>({
    fnName: "get_user_renewals",
    args: {
      switch_user_id: switchUserId,
      user_credentials_file_id: fileId,
    },
  });
};
