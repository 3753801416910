import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";
import { Json } from "../../common/types/database.types";
export type ReferralType = {
  id: string;
  status_name: string;
  status_id: number;
  referred_at: string;
};

export const getReferrals = async (): Promise<ReferralType[]> => {
  return await callSupabaseFnFromClient<ReferralType[]>({
    fnName: "get_user_referrals",
  });
};
