import React from "react";
import RenewalTable from "@/components/renewal/RenewalTable";
import { createFileRoute } from "@tanstack/react-router";
import { queryClient } from "../../../../ajax/queryClient";
import { renewalsQueryOptions } from "../../../../ajax/queries";
import { useSuspenseQuery } from "@tanstack/react-query";

export const Route = createFileRoute("/_authenticated/provider/renewals/")({
  loader: () => queryClient.ensureQueryData(renewalsQueryOptions()),
  component: RenewalPage,
});

function RenewalPage() {
  const { data: result } = useSuspenseQuery(renewalsQueryOptions());

  return (
    <div className="min-h-screen bg-gray-100 p-2 pt-4 md:pt-8 md:p-8">
      <RenewalTable renewals={result} />
    </div>
  );
}
