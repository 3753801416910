import { useMutation } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../Loading";
import { Renewal } from "@/ajax/renewals/getRenewals";
import { updateRenewal } from "../../ajax/renewals/updateRenewal";
import { createRenewal } from "../../ajax/renewals/createRenewal";
import { useState } from "react";
import { Alert } from "../ui/alert";

const formSchema = z.object({
  title: z.string().min(2, {
    message: "Title must be at least 2 characters.",
  }),
  issued_at: z.date().optional(),
  expires_at: z.date({
    required_error: "Expiration date is required.",
  }),
});

type RenewalFormValues = z.infer<typeof formSchema>;

export function RenewalForm({
  renewal,
  userId,
  onComplete,
}: {
  renewal:
    | Renewal
    | {
        source_user_credentials_file_id: string | null;
      };
  userId?: string | null;
  onComplete: (id: string) => void;
}) {
  const [error, setError] = useState<string | null>(null);
  const isNewRenewal = !("id" in renewal);

  const form = useForm<RenewalFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: isNewRenewal
      ? {
          title: "",
          issued_at: new Date(),
          expires_at: new Date(),
        }
      : {
          title: (renewal as Renewal).title,
          issued_at: renewal.issued_at
            ? new Date((renewal as Renewal).issued_at)
            : undefined,
          expires_at: new Date((renewal as Renewal).expires_at),
        },
  });

  const updateMutation = useMutation({
    mutationFn: updateRenewal,
    networkMode: "always",
    onSuccess: (data) => onComplete(data.id),
    onError: () => {
      setError("Unable to save. Please try again.");
    },
  });

  const createMutation = useMutation({
    mutationFn: createRenewal,
    networkMode: "always",
    onSuccess: (data) => onComplete(data.id),
    onError: () => {
      setError("Unable to create. Please try again.");
    },
  });

  const onSubmit = (data: RenewalFormValues) => {
    if (isNewRenewal) {
      createMutation.mutate({
        title: data.title,
        issued_at: data.issued_at ? data.issued_at.toISOString() : undefined,
        expires_at: data.expires_at.toISOString(),
        userId: userId ?? undefined,
        source_user_credentials_file_id:
          renewal.source_user_credentials_file_id,
      });
    } else {
      updateMutation.mutate({
        id: (renewal as Renewal).id,
        title: data.title,
        issued_at: data.issued_at ? data.issued_at.toISOString() : undefined,
        expires_at: data.expires_at.toISOString(),
        userId: userId ?? undefined,
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Renewal title" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="issued_at"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Issued Date</FormLabel>
              <FormControl>
                <div className="relative">
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    customInput={
                      <Input
                        value={
                          field.value ? format(field.value, "MM-dd-yyyy") : ""
                        }
                        onChange={(e) => {
                          const date = parse(
                            e.target.value,
                            "MM-dd-yyyy",
                            new Date(),
                          );
                          if (date instanceof Date && !isNaN(date.getTime())) {
                            field.onChange(date);
                          }
                        }}
                      />
                    }
                    dateFormat="MM-dd-yyyy"
                    wrapperClassName="w-full"
                  />
                  <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="expires_at"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Expiration Date</FormLabel>
              <FormControl>
                <div className="relative">
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    customInput={
                      <Input
                        value={
                          field.value ? format(field.value, "MM-dd-yyyy") : ""
                        }
                        onChange={(e) => {
                          const date = parse(
                            e.target.value,
                            "MM-dd-yyyy",
                            new Date(),
                          );
                          if (date instanceof Date && !isNaN(date.getTime())) {
                            field.onChange(date);
                          }
                        }}
                      />
                    }
                    dateFormat="MM-dd-yyyy"
                    wrapperClassName="w-full"
                  />
                  <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {error && <Alert variant="destructive">{error}</Alert>}
        <Button
          disabled={updateMutation.isPending || createMutation.isPending}
          size="none"
          className="w-full py-4"
          type="submit"
        >
          {updateMutation.isPending || createMutation.isPending ? (
            <Loading className="w-6 h-6" />
          ) : isNewRenewal ? (
            "Create Renewal"
          ) : (
            "Update Renewal"
          )}
        </Button>
      </form>
    </Form>
  );
}
