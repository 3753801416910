import React, { useState, useEffect } from "react";
import { Download, FileText, Edit, Pencil, Trash2 } from "lucide-react";
import { Json } from "@/common/types/database.types";
import Loading from "../Loading";
import { filesQueryOptions, renewalsQueryOptions } from "@/ajax/queries";
import {
  useSuspenseQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { FilePreviewEdit } from "./FilePreviewEdit";
import { Button } from "../ui/button";
import { deleteFile } from "@/ajax/documents/deleteFile";
import { DeleteDialog } from "../DeleteDialog/DeleteDialog";
import { getFileSignedUrl } from "@/ajax/documents/getFileSignedUrl";
import PDFViewer from "../PDFViewer/PDFViewer";
import { parse } from "date-fns";

const extensionToContentType: { [extension: string]: string } = {
  pdf: "application/pdf",
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  gif: "image/gif",
};

function bufferToBase64(blob: Blob): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (evt) {
      const dataUrl = evt.target?.result;

      if (typeof dataUrl !== "string") {
        resolve(null);
        return;
      }
      const base64 = dataUrl.split(",")[1];
      resolve(base64);
    };

    reader.onerror = function (err) {
      reject(err);
    };

    reader.readAsDataURL(blob);
  });
}

type File = {
  generated_title: string | null;
  file_name: string;
  id: string;
  data: null | Json;
  url?: string;
  created_at: string;
  type_id: number;
  type_name: string;
  expires_at: string | null;
};

async function updateVersionSource({
  version,
  user,
}: {
  version: File;
  user: {
    id: string;
  };
}) {
  let fileId = version.id;
  let extension = version.file_name.split(".").pop();

  if (extension?.toLowerCase() === "heic") {
    fileId += "_jpg";
    extension = "jpg";
  }
  const fileData = await getFileSignedUrl({
    userId: user.id,
    fileId,
  });
  return {
    ...version,
    url: fileData,
  };
}
const formatDateTime = (datetimeString: string) => {
  const date = new Date(datetimeString);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);
};

const formatDate = (dateString: string) => {
  const date = parse(dateString, "yyyy-MM-dd", new Date());
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(date);
};

const FileVersionPreview = ({
  file,
  user,
  onDelete,
}: {
  file: File;
  user: {
    id: string;
  };
  onDelete: () => void;
}) => {
  const [versionSource, setSelectedVersion] = useState<null | File>(null);
  const [showEditFile, setShowEditFile] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const queryClient = useQueryClient();

  const renewalsQuery = useSuspenseQuery(
    renewalsQueryOptions({
      fileId: file.id,
      switchUserId: user.id,
    }),
  );
  const filesQuery = useSuspenseQuery(
    filesQueryOptions({
      id: file.id,
      switchUserId: user.id,
    }),
  );

  const deleteMutation = useMutation({
    mutationFn: deleteFile,
    onError: (error) => {
      console.error("Error deleting file:", error);
      alert("Failed to delete file. Please try again.");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["files"] });
      queryClient.invalidateQueries({ queryKey: ["renewals"] });
      onDelete();
    },
  });

  useEffect(() => {
    (async () => {
      const version = await updateVersionSource({
        version: filesQuery.data[0],
        user,
      });
      setSelectedVersion(version);
    })();
  }, [filesQuery.data]);

  const handleDelete = async () => {
    deleteMutation.mutate({ id: file.id });
  };

  const handleDownload = async () => {
    if (versionSource?.url) {
      const a = document.createElement("a");
      a.href = await getFileSignedUrl({
        userId: user.id,
        fileId: versionSource.id,
        download: true,
      });
      a.download = versionSource.file_name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const renderOCRData = (data: any, level = 0) => {
    if (typeof data !== "object" || data === null) {
      return (
        <span className="text-gray-800">
          {data == null ? "None" : JSON.stringify(data)}
        </span>
      );
    }

    return (
      <ul className={`space-y-1 ${level === 0 ? "mt-2" : "ml-4"}`}>
        {Object.entries(data).map(([key, value]) => (
          <li key={key} className="font-mono">
            <span className="font-medium text-blue-600 break-all">{key}:</span>{" "}
            {Array.isArray(value) ? (
              <span>{renderOCRData(value, level + 1)}</span>
            ) : typeof value === "object" && value !== null ? (
              <span>{renderOCRData(value, level + 1)}</span>
            ) : (
              <span className="text-gray-800 break-all">
                {value == null ? "None" : JSON.stringify(value)}
              </span>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const renderPreview = (version: File) => {
    let extension = version.file_name.split(".").pop();
    const isPDF = extension?.toLowerCase() === "pdf";
    if (isPDF && version.url) {
      return <PDFViewer url={version.url} />;
      // return (
      //   <object
      //     data={version.url}
      //     type="application/pdf"
      //     width="100%"
      //     height="600px"
      //     className="rounded border border-gray-200"
      //   >
      //     <div className="flex flex-col items-center justify-center h-64 bg-gray-100 rounded">
      //       <FileText className="h-12 w-12 text-gray-400 mb-4" />
      //       <p className="text-gray-600 mb-4">Unable to display PDF.</p>
      //       <a href={version.url} target="_blank" rel="noopener noreferrer">
      //         <Download className="h-4 w-4 mr-2" />
      //         Download PDF
      //       </a>
      //     </div>
      //   </object>
      // );
    } else if (version.url) {
      return (
        <img
          src={version.url}
          alt={version.generated_title || version.file_name || "Image"}
          className="max-w-full max-h-[70vh] object-contain mx-auto rounded"
        />
      );
    } else {
      return <div>Unable to load preview</div>;
    }
  };

  if (!versionSource) {
    return (
      <div className="flex w-full item-center justify-center">
        <Loading className="w-20 h-20" />
      </div>
    );
  }

  return (
    <div className={`w-full`}>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          {renderPreview(versionSource)}
        </div>
        <div className="w-full md:w-1/2">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h3 className="text-2xl font-medium">
                {versionSource.generated_title || versionSource.file_name}
              </h3>
              {versionSource.generated_title && (
                <h4 className="text-md mt-1">
                  <span className="text-gray-500">
                    ({versionSource.file_name})
                  </span>
                </h4>
              )}
              <h4 className="mt-1">{versionSource.type_name}</h4>
              <p className="mt-1">{formatDateTime(versionSource.created_at)}</p>
              {versionSource.expires_at && (
                <p className="mt-1">
                  Expires: {formatDate(versionSource.expires_at)}
                </p>
              )}
              <div className="mt-4 flex space-x-2">
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => setShowEditFile(true)}
                >
                  <Pencil aria-label="Edit title" className="w-4 h-4" />
                </Button>
                <Button size="sm" variant="outline" onClick={handleDownload}>
                  <Download aria-label="Download file" className="w-4 h-4" />
                </Button>
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => setShowDeleteDialog(true)}
                >
                  <Trash2
                    aria-label="Delete file"
                    className="w-4 h-4 text-red-500"
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEditFile && (
        <FilePreviewEdit
          file={versionSource}
          onClose={() => setShowEditFile(false)}
        />
      )}

      <DeleteDialog
        description={`This will permanently delete the file and and all associated renewals. Please make sure this is correct. This action cannot be undone.`}
        open={showDeleteDialog}
        onOpenChange={setShowDeleteDialog}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default FileVersionPreview;
