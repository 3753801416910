"use client";
import Intercom from "@intercom/messenger-js-sdk";

export const IntercomProvider = ({
  user,
}: {
  user: {
    id: string;
    email: string | undefined;
    created_at: string;
  };
}) => {
  if (user) {
    Intercom({
      app_id: "qhfaor0a",
      user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      created_at: +new Date(user.created_at) / 1000, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
  }

  return <></>;
};
