import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export const updateWorkShiftPreferences = async ({
  searchStatusId,
  preferredCities,
  preferredFacilities,
  shiftContractDurationId,
  openToOtherCities,
}: {
  searchStatusId: number;
  shiftContractDurationId: number | null;
  preferredCities: string[];
  preferredFacilities: string[];
  openToOtherCities: boolean;
}) => {
  return callSupabaseFnFromClient<string>({
    fnName: "update_user_work_shift_preferences",
    args: {
      search_status_id: searchStatusId,
      preferred_cities: preferredCities,
      preferred_facilities: preferredFacilities,
      open_to_other_cities: openToOtherCities,
      shift_contract_duration_id: shiftContractDurationId ?? undefined,
    },
  });
};
