import { getAccessToken } from "../clients/supabase";
import { API_HOST } from "../../consts/api_host";

export const processFiles = async ({ fileIds }: { fileIds: string[] }) => {
  await fetch(`${API_HOST}/user-credentials-file/process`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },

    body: JSON.stringify({ ids: fileIds }),
  });
};
