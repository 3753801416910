import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
export type AdminSearchUser = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
};
export const getUsers = async ({
  search,
}: {
  search: string;
}): Promise<AdminSearchUser[]> => {
  return callSupabaseFnFromClient<AdminSearchUser[]>({
    fnName: "get_users",
    args: {
      search,
    },
  });
};
