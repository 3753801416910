import { queryOptions } from "@tanstack/react-query";
import { getRenewals } from "./renewals/getRenewals";
import { getProviderUser } from "./auth/getProviderUser";
import { getFiles } from "./documents/getFiles";
import { getProviderCommunicationPreferences } from "./settings/getProviderCommunicationPreferences";
import { getFileTypes } from "./documents/getFileTypes";
import { getReferrals } from "./referrals/getReferrals";
import { createReferralLink } from "./referrals/createReferralLink";

export const filesQueryOptions = ({
  switchUserId,
  id,
}: { switchUserId?: string; id?: string } = {}) =>
  queryOptions({
    queryKey: ["files", switchUserId, id],
    queryFn: () =>
      getFiles({
        id,
        switchUserId,
      }),
  });

export const providerUserQuery = () =>
  queryOptions({
    queryKey: ["providerUser"],
    queryFn: getProviderUser,
  });
export const renewalsQueryOptions = ({
  switchUserId,
  fileId,
}: {
  switchUserId?: string;
  fileId?: string;
} = {}) =>
  queryOptions({
    queryKey: ["renewals", switchUserId, fileId],
    queryFn: () =>
      getRenewals({
        switchUserId,
        fileId,
      }),
  });

export const communicationPreferencesQueryOptions = () =>
  queryOptions({
    queryKey: ["communicationPreferences"],
    queryFn: () => getProviderCommunicationPreferences(),
  });

export const fileTypesQueryOptions = () =>
  queryOptions({
    queryKey: ["userCredentialsFileTypes"],
    queryFn: () => getFileTypes(),
  });

export const referralsQueryOptions = () =>
  queryOptions({
    queryKey: ["referrals"],
    queryFn: () => getReferrals(),
  });

export const createReferralLinkQueryOptions = () =>
  queryOptions({
    queryKey: ["createReferralLink"],
    queryFn: () => createReferralLink(),
  });
