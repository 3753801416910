import Loading from "../Loading";

export const LoadingScreen = () => {
  return (
    <div className="flex flex-col mt-8 items-center justify-center w-full">
      {/*<svg*/}
      {/*  xmlns="http://www.w3.org/2000/svg"*/}
      {/*  viewBox="330 160 335 700"*/}
      {/*  width="200"*/}
      {/*  height="200"*/}
      {/*>*/}
      {/*  <path*/}
      {/*    style={{*/}
      {/*      fill: "#0f3490",*/}
      {/*      stroke: "#0f3490",*/}
      {/*    }}*/}
      {/*    d="M291.05,613.79c-0.01,3.72,1.25,6.16,4.55,8.02c40.57,22.9,81.09,45.88,121.62,68.84   c26.36,14.93,52.75,29.81,79.05,44.85c2.9,1.66,5.02,1.71,7.96,0.03c25.97-14.83,52.05-29.46,78.07-44.2   c26.02-14.74,52-29.54,78.01-44.29c14.86-8.43,29.71-16.9,44.66-25.17c4.24-2.35,5.08-5.75,5.07-10.19   c-0.09-50.44-0.05-100.88-0.05-151.32c0-23.95-0.19-47.91,0.14-71.86c0.08-5.5-1.93-8.65-6.39-11.14   c-21.37-11.91-42.7-23.9-64.03-35.88c-13.71-7.7-27.35-15.53-41.12-23.12c-3.86-2.12-5.42-4.81-5.39-9.3   c0.17-30.19,0.07-60.37,0.11-90.56c0.01-6.85,0.92-7.25,6.92-3.85c37.66,21.37,75.35,42.69,113.01,64.07   c28.03,15.91,55.99,31.95,84.1,47.71c3.86,2.17,4.92,4.82,4.9,8.91c-0.12,23.65-0.05,47.31-0.05,70.96   c0,84.91-0.06,169.82,0.12,254.74c0.02,6.94-1.62,10.98-8.33,14.6c-25.13,13.54-49.7,28.12-74.53,42.2   c-53.71,30.46-107.47,60.86-161.2,91.3c-17.06,9.66-34.19,19.21-51.1,29.12c-4.68,2.74-8.44,3-13.27,0.23   c-41.77-23.94-83.64-47.72-125.54-71.44c-42.24-23.91-84.56-47.69-126.84-71.55c-12.69-7.16-25.32-14.41-38.04-21.52   c-3.93-2.2-5.76-5.02-5.71-9.8c0.23-22.98,0.1-45.96,0.1-68.94c0-84.79,0.12-169.58-0.18-254.37c-0.03-8.42,2.3-13.15,9.96-17.37   c37.77-20.78,75.13-42.31,112.64-63.56c26.7-15.12,53.4-30.23,80.13-45.3c5.47-3.09,6.32-2.64,6.33,3.49   c0.05,30.58-0.01,61.15,0.06,91.73c0.01,3.69-1.19,5.96-4.6,7.88c-30.27,17.08-60.41,34.39-90.61,51.6   c-4.89,2.79-9.91,5.33-14.8,8.12c-3.27,1.86-5.82,3.97-5.78,8.55C291.21,408.97,291.13,565.1,291.05,613.79z"*/}
      {/*  />*/}
      {/*  <path*/}
      {/*    style={{*/}
      {/*      fill: "#08d8c2",*/}
      {/*      stroke: "#08d8c2",*/}
      {/*    }}*/}
      {/*    d="M546.25,331.16c0,45.95-0.07,91.9,0.1,137.85c0.02,5.04-1.23,8.21-5.94,10.67   c-12.2,6.38-24.18,13.19-36.09,20.1c-3.51,2.03-6.29,1.88-9.67-0.11c-12.39-7.25-24.9-14.31-37.4-21.38   c-2.27-1.28-3.46-3.02-3.42-5.62c0.02-1.56-0.03-3.11-0.03-4.67c0-91.7,0.07-183.41-0.15-275.11c-0.02-6.43,2.26-9.86,7.69-12.73   c11.49-6.06,22.73-12.59,33.94-19.15c3.06-1.79,5.68-1.96,8.7-0.25c12.48,7.10,24.95,14.23,37.54,21.14   c4.06,2.23,4.8,5.42,4.79,9.66C546.21,238.1,546.25,284.63,546.25,331.16z"*/}
      {/*  />*/}
      {/*</svg>*/}
      <Loading className="mt-4 w-10 h-10" />
    </div>
  );
};
