import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
export type RenewalId = {
  id: string;
};
export const updateRenewal = async ({
  id,
  userId,
  title,
  issued_at,
  expires_at,
}: {
  id: string;
  userId?: string | undefined;
  title: string;
  issued_at: string | undefined;
  expires_at: string;
}) => {
  return callSupabaseFnFromClient<RenewalId>({
    fnName: "update_user_renewal",
    args: {
      switch_user_id: userId,
      update_id: id,
      title,
      issued_at,
      expires_at,
    },
  });
};
