import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { ProviderLayout } from "@/components/Layouts/ProviderLayout";
import { IntercomProvider } from "@/components/Chat/IntercomProvider";
import { useEffect } from "react";
import { supabase } from "@/ajax/clients/supabase";
import { ProviderOnboardingStep } from "../common/enums/ProviderOnboardingStep";
import { queryClient } from "@/ajax/queryClient";
import { providerUserQuery } from "../ajax/queries";

export const Route = createFileRoute("/_authenticated")({
  beforeLoad: async ({ location, context }) => {
    const providerUser = await queryClient.fetchQuery(providerUserQuery());

    if (!context.user || !providerUser) {
      throw redirect({
        to: "/auth/signin",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      });
    }

    const isOnboarding =
      providerUser.onboarding_step_id < ProviderOnboardingStep.REVIEW;

    // Redirect to the onboarding if it's not completed
    if (isOnboarding && location.pathname.startsWith("/provider/")) {
      throw redirect({
        to: "/provider-onboarding",
      });
    }

    return {
      user: context.user,
      providerUser,
      isOnboarding,
    };
  },
  component: () => {
    const { user, isOnboarding } = Route.useRouteContext();

    useEffect(() => {
      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((event, session) => {
        if (event === "SIGNED_OUT") {
          document.location.reload();
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }, []);

    return (
      <ProviderLayout showSidebar={!isOnboarding}>
        <Outlet />
        <IntercomProvider user={user} />
      </ProviderLayout>
    );
  },
});
