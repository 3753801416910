import React from "react";
import { Link, useRouterState } from "@tanstack/react-router";

const SidebarItem = ({ item, onClick }: any) => {
  const router = useRouterState();

  const isActive = (item: any) => {
    if (router.location.pathname.startsWith(item.route)) return true;
    return false;
  };

  const isItemActive = isActive(item);

  return (
    <Link
      to={item.route}
      onClick={onClick}
      className={`flex items-center gap-3 rounded-lg px-3 py-2 text-lg transition-all mb-1 ${isItemActive ? "bg-primary text-white" : "hover:text-primary"}`}
    >
      {item.icon}
      {item.label}
    </Link>
  );
};

export default SidebarItem;
