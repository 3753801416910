"use client";
import { Label } from "@/components/ui/label";
import Select from "react-select";
import { STATES } from "@/consts/states";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { usePhoneInput } from "react-international-phone";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { Input } from "../ui/input";

type Inputs = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: string;
  state: string;
  city: string;
  stateLicenses: string[];
};

export default function PersonalInfoForm({
  onSubmit,
}: {
  onSubmit: (data: any) => void;
}) {
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isSubmitted },
  } = useForm<Inputs>({
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      dateOfBirth: "",
      state: "",
      city: "",
      stateLicenses: [],
    },
    mode: "onSubmit",
  });

  const { inputValue, handlePhoneValueChange } = usePhoneInput({
    defaultCountry: "us",
    disableDialCodeAndPrefix: true,
    value: "",
    onChange: ({ phone }) => {
      setValue("phoneNumber", phone, {
        shouldValidate: isSubmitted,
      });
    },
  });

  const states = STATES.map((state) => ({
    value: state[1],
    label: state[0],
  }));
  const selectedState = states.find((state) => state.value === watch("state"));
  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="p-6.5">
        <div className="mb-4.5">
          <Label htmlFor="first_name_input">
            First name <span className="text-meta-1">*</span>
          </Label>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Input
                id="first_name_input"
                type="text"
                placeholder="Enter your first name"
                {...field}
              />
            )}
          />
        </div>
        <div className="mb-4.5">
          <Label htmlFor="last_name_input">
            Last Name <span className="text-meta-1">*</span>
          </Label>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <Input
                id="last_name_input"
                type="text"
                placeholder="Enter your last name"
                {...field}
              />
            )}
          />
        </div>
        <div className="mb-4.5">
          <Label htmlFor="telephone_input">
            Phone Number <span className="text-meta-1">*</span>
          </Label>
          <Input
            id="telephone_input"
            type="tel"
            placeholder="Enter your phone number"
            value={inputValue}
            onChange={handlePhoneValueChange}
            inputMode="tel"
            autoComplete="tel"
          />
        </div>
        <div className="mb-4.5">
          <Label htmlFor="date_of_birth_input">
            Date of Birth <span className="text-meta-1">*</span>
          </Label>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => (
              <Input
                id="date_of_birth_input"
                type="date"
                placeholder="mm/dd/yyyy"
                {...field}
              />
            )}
          />
        </div>
        <div className="mb-4.5">
          <Label htmlFor="state_input">
            State <span className="text-meta-1">*</span>
          </Label>

          <Select
            placeholder="Select state"
            options={states}
            value={selectedState}
            classNames={{
              control: () => "py-2",
            }}
            onChange={(
              selectedOption: { value: string; label: string } | null,
            ) => {
              setValue("state", selectedOption?.value ?? "");
            }}
          />
        </div>
        <div className="mb-4.5">
          <Label htmlFor="email_input">
            City <span className="text-meta-1">*</span>
          </Label>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <Input
                id="city_input"
                type="city"
                placeholder="Enter your city"
                {...field}
              />
            )}
          />
        </div>

        <button
          type="submit"
          className="flex w-full justify-center rounded bg-primary p-3 font-medium text-white hover:bg-opacity-90"
        >
          Save
        </button>
      </div>
    </form>
  );
}
