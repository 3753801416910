import axios from "axios";
import { PhoneValidateAPI } from "@/common/types/api/PhoneValidateAPI";
import { API_HOST } from "../../consts/api_host";
import { getAccessToken } from "../clients/supabase";

type SendPhoneVerificationResponse = {
  success: boolean;
};
export const sendPhoneVerification = async (body: {
  phoneNumber: string;
}): Promise<SendPhoneVerificationResponse> => {
  try {
    const response = await axios.post<SendPhoneVerificationResponse>(
      `${API_HOST}/phone/send-verification`,
      body,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};
