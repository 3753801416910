import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ArrowUpDown,
  Clock,
  EllipsisVertical,
  FileText,
  Search,
} from "lucide-react";
import AsyncSelect from "react-select/async";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useQuery } from "@tanstack/react-query";
import { formatDate } from "date-fns";
import { createFileRoute, Link } from "@tanstack/react-router";
import { filesQueryOptions } from "@/ajax/queries";
import { FileType } from "@/ajax/documents/getFiles";
import { useMemo } from "react";
import { SingleValue } from "react-select";
import { Label } from "@/components/ui/label";
import { getUsers } from "../../../../ajax/users/getUsers";
import FilePreviewModal from "../../../../components/FilePreview/FilePreviewModal";
import { AdminRenewalsModal } from "../../../../components/admin_renewals/AdminRenewalsModal";

export const Route = createFileRoute("/_admin/admin/documents/")({
  component: DocumentsPage,
});

const columnSize: { [cat: string]: string } = {
  title: "w-[90%] md:w-[50%]",
  category: "w-[20%] hidden md:table-cell",
  created_at: "w-[20%] hidden md:table-cell",
  actions: "w-[10%]",
};

function DocumentsPage() {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [previewFile, setPreviewFile] = React.useState<FileType | null>(null);
  const [renewalsFile, setRenewalsFile] = React.useState<FileType | null>(null);
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedUser, setSelectedUser] = React.useState<{
    value: string;
    label: string;
  } | null>(null);

  const filesQueryState = useQuery(
    filesQueryOptions({ switchUserId: selectedUser?.value }),
  );

  const columns: ColumnDef<FileType>[] = useMemo(
    () => [
      {
        id: "title",
        accessorFn: (row) => ({
          title: row.generated_title,
          fileName: row.file_name,
        }),
        header: "Title",
        cell: ({ row }) => {
          const value: {
            title: string;
            fileName: string;
          } = row.getValue("title");
          return (
            <div className="flex items-center">
              <FileText className="hidden md:block min-w-4 w-4 min-h-4 h-4 mr-2" />
              <div>
                <div className="font-medium">
                  {value.title ? value.title : value.fileName || "-"}
                </div>
                {value.title && (
                  <div className="text-gray-500 hidden md:block">
                    {value.fileName}
                  </div>
                )}
                <div className="md:hidden">
                  {Boolean(row.getValue("category")) && (
                    <div>Type: {row.getValue("category")}</div>
                  )}
                  <div>
                    {formatDate(row.getValue("created_at"), "MMM dd, yyyy")}
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: "category",
        accessorKey: "category",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              className="p-0"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
            >
              Category
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <div>{row.getValue("category") ? row.getValue("category") : "-"}</div>
        ),
      },
      {
        id: "created_at",
        accessorKey: "created_at",
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Date
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          return (
            <div>
              <div className="md:hidden">
                {formatDate(row.getValue("created_at"), "MM/dd/yy")}
              </div>
              <div className="hidden md:block">
                {formatDate(row.getValue("created_at"), "MMM dd, yyyy")}
              </div>
            </div>
          );
        },
      },
      {
        id: "actions",
        enableHiding: false,
        // size: 10,
        cell: ({ row }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <EllipsisVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem>
                  <Button
                    variant="none"
                    size="none"
                    onClick={() => setPreviewFile(row.original)}
                  >
                    <div className="flex items-center">
                      <Search className="w-3 h-3 mr-2" /> Source
                    </div>
                  </Button>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Button
                    variant="none"
                    size="none"
                    onClick={() => setRenewalsFile(row.original)}
                  >
                    <div className="flex items-center">
                      <Clock className="w-3 h-3 mr-2" /> Renewals
                    </div>
                  </Button>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: filesQueryState.data ?? [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const onLoadOptions = async (inputValue: string) => {
    const users = await getUsers({
      search: inputValue,
    });

    return users.map((u) => ({
      label: `${u.first_name} ${u.last_name} (${u.email})`,
      value: u.id,
    }));
  };

  return (
    <div className="w-full p-2 pt-4 md:pt-8 md:p-8 min-h-screen bg-gray-100">
      <div>
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-title-md2 font-bold text-black dark:text-white">
            Documents
          </h2>
        </div>
        <div className="rounded-md border bg-white animate-fade-in-delay-2">
          <div className="p-2">
            <Label>User</Label>
            <AsyncSelect
              placeholder="Select user"
              classNames={{
                control: (state) => "py-2",
              }}
              loadOptions={onLoadOptions}
              value={selectedUser}
              onChange={(
                selectedOption: SingleValue<{ value: string; label: string }>,
              ) => {
                setSelectedUser(
                  selectedOption
                    ? {
                        value: selectedOption.value,
                        label: selectedOption.label,
                      }
                    : null,
                );
              }}
            />
          </div>

          {filesQueryState.data && selectedUser && (
            <Table className="w-full table-fixed">
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          className={columnSize[header.column.id]}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className={columnSize[cell.column.id]}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      <div className="flex flex-col items-center justify-center h-32 sm:h-64 text-center">
                        <FileText className="w-12 h-12 sm:w-16 sm:h-16 mb-3 sm:mb-4" />
                        <p className="text-lg sm:text-xl font-medium text-gray-900 mb-1 sm:mb-2">
                          No documents yet
                        </p>
                        <p className="text-sm">
                          User does not have any documents
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
      {previewFile && selectedUser && (
        <FilePreviewModal
          user={{ id: selectedUser.value }}
          file={previewFile}
          onClose={() => setPreviewFile(null)}
        />
      )}
      {renewalsFile && selectedUser && (
        <AdminRenewalsModal
          user={{ id: selectedUser.value }}
          file={renewalsFile}
          onClose={() => setRenewalsFile(null)}
        />
      )}
    </div>
  );
}
