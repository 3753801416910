import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export const updateProviderCommunicationPreferences = async ({
  renewalsPushNotifications,
  renewalsPhoneNotifications,
  renewalsEmailNotifications,
}: {
  renewalsEmailNotifications: boolean;
  renewalsPhoneNotifications: boolean;
  renewalsPushNotifications: boolean;
}) => {
  return callSupabaseFnFromClient<string>({
    fnName: "update_user_provider_communication_preferences",
    args: {
      renewals_push_notifications: renewalsPushNotifications,
      renewals_phone_notifications: renewalsPhoneNotifications,
      renewals_email_notifications: renewalsEmailNotifications,
    },
  });
};
