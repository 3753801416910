import { supabase } from "../clients/supabase";
import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";

export type UserCredentialsFileType = {
  id: number;
  name: string;
  category: string;
};

export const getFileTypes = async () => {
  return await callSupabaseFnFromClient<UserCredentialsFileType[]>({
    fnName: "get_user_credentials_file_types",
  });
};
