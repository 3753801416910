"use client";
import React, { useState, useEffect, useRef } from "react";
import { updateProviderOnboardingStep } from "@/ajax/onboarding/updateProviderOnboardingStep";
import { ProviderOnboardingStep } from "@/common/enums/ProviderOnboardingStep";
import { ProviderUser } from "@/common/types/ProviderUser";
import { Button } from "@/components/ui/button";
import { CredentialUploadMissingDialog } from "./CredentialUploadMissingDialog";
import { DocumentCategory } from "@/consts/document_categories";
import { Documents } from "../Documents/Documents";

const CredentialUploadStep = ({
  onComplete,
  providerUser,
}: {
  onComplete: () => void;
  providerUser: ProviderUser;
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [missingDocuments, setMissingDocuments] = useState<
    DocumentCategory[] | null
  >(null);
  const documentsRef = useRef<{
    getMissingDocuments: () => DocumentCategory[];
  } | null>(null);

  useEffect(() => {
    if (documentsRef.current) {
      setMissingDocuments(documentsRef.current.getMissingDocuments());
    }
  }, []);

  const handleFinishedUploading = async () => {
    const missingDocuments = documentsRef.current?.getMissingDocuments();
    if (!missingDocuments) {
      return;
    }
    setMissingDocuments(missingDocuments);
    if (missingDocuments.length > 0) {
      setShowDialog(true);
    } else {
      await handleComplete();
    }
  };
  const handleComplete = async () => {
    await updateProviderOnboardingStep(ProviderOnboardingStep.COMPLETED);
    onComplete();
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-1 animate-fade-in">
        Upload Your Credentialing Documents
      </h2>
      <p className="text-gray-600 mb-8 animate-fade-in-delay-2">
        We will help you renew your documents and get you credentialed at all
        our facilities.
      </p>
      <Documents providerUser={providerUser} ref={documentsRef} />
      <Button
        size="none"
        className="w-full mt-8 py-3"
        onClick={handleFinishedUploading}
      >
        Finished Uploading
      </Button>
      {showDialog && missingDocuments && (
        <CredentialUploadMissingDialog
          missingDocuments={missingDocuments}
          onContinue={handleComplete}
          onBack={() => setShowDialog(false)}
        />
      )}
    </div>
  );
};

export default CredentialUploadStep;
